import { useEffect } from 'react';
import './App.css';
import RainyDay from './rainyday.js';

function App() {

  

  return (
<>

</>
  );
}

export default App;
